/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface InputStrings {
  [everyProp: string]: string | boolean | undefined;
}

type ValidationRule = (val: string | boolean) => boolean;
type Errors<Inputs> = Partial<{ [P in keyof Inputs]: string | undefined }>;
export type Validator = (val: string) => string | void;
type MakeValidator = (msg?: string) => Validator;
type MakeValidatorWithArg<T> = (arg: T, msg?: string) => Validator;

export type ValidationSchema<Inputs> = Partial<{ [P in keyof Inputs]: Validator[] }>;

export const composeValidator: (
  rule: ValidationRule, msg: string
) => Validator = (rule, msg) => (val) => {
  if (!rule(val)) {
    return msg;
  }
  return undefined;
};

// Rules
const validateEmail: ValidationRule = (val) => (val as string).indexOf('@') !== -1;
const validateRequired: ValidationRule = (val) => (typeof val === 'string' ? val.trim().length > 0 : !!val);
const validateNumber: ValidationRule = (val) => !Number.isNaN(Number.parseFloat((val as string)));
const validateInt: ValidationRule = (val) => !Number.isNaN(Number.parseInt((val as string), 10));
const validateBoolean: ValidationRule = (val) => val === true || val === false;
const validateTruthy: ValidationRule = (val) => val === true;
const validateMinLength: (
  min: number
) => ValidationRule = (min) => (val) => (val as string).trim().length >= min;
const validateEquality: (
  toDiff: () => string
) => ValidationRule = (toDiff) => (val) => toDiff() === val;

// Validators from Functions
export const isRequired: MakeValidator = (message = 'Required') => composeValidator(validateRequired, message);
export const isBoolean: MakeValidator = (message = 'Required') => composeValidator(validateBoolean, message);
export const isTrue: MakeValidator = (message = 'Required') => composeValidator(validateTruthy, message);
export const isEmail: MakeValidator = (msg = 'Valid Email Required') => composeValidator(validateEmail, msg);
export const isNumber: MakeValidator = (msg = 'Number Required') => composeValidator(validateNumber, msg);
export const isInt: MakeValidator = (msg = 'Integer Required') => composeValidator(validateInt, msg);
export const isMinLength: MakeValidatorWithArg<number> = (
  min, msg,
) => composeValidator(validateMinLength((min as number)), msg || `Min Length ${min} required`);
export const isEqual: MakeValidatorWithArg<() => string> = (
  toDiff: () => string, msg,
) => composeValidator(validateEquality(toDiff as () => string), msg || `Equality to ${toDiff()} required`);

export class Validation<I extends InputStrings> {
  public schema: ValidationSchema<I>

  constructor(schema: ValidationSchema<I>) {
    this.schema = schema;
  }

  // validateField = (name, values) => 'not implemented'
  validate: (
    inputs: I
  ) => Errors<I> = (inputs) => Object.keys(this.schema).reduce((errors, key) => {
    let errorMessage;
    const validators = this.schema[key];
    const value = inputs[key];
    Object.keys(validators as { [P in keyof I]: Validator[]; }[string]).some((i) => {
      const validator = (validators as { [key: string]: any })[i as string];
      const res = validator(value);
      if (res) errorMessage = res;
      return res;
    });
    // for (const i of Object.keys(validators as { [P in keyof I]: Validator[]; }[string])) {
    //   const validator = (validators as { [key:string]: any })[i as string];
    //   const res = validator(value);
    //   if (res) {
    //     errorMessage = res;
    //     break;
    //   }
    // }
    if (errorMessage) {
      // console.log(errorMessage);
      (errors as { [key: string]: string })[key as string] = errorMessage;
    }
    return errors;
  }, {})
}
