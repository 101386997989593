
import ReactPixel from 'react-facebook-pixel';
import {
  defineComponent,
  provide,
  watchEffect,
  ref,
  onMounted,
  onUnmounted,
  inject,
  computed,
  defineAsyncComponent,
} from 'vue';
import { downloadFromLink, isMobile } from '@/api';
import axios, { AxiosResponse } from 'axios';
import { useWindowSize } from 'vue-window-size';
import Header from '@/components/Header.vue';
// import Footer from '@/components/Footer.vue';
import Body from '@/components/Body.vue';
// import Order from '@/components/Order.vue';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import PresentationOrder from '@/components/PresentationOrder.vue';
// import TariffOrder from '@/components/TariffOrder.vue';
// import TimeTable from '@/components/TimeTable.vue';
import { useToast } from 'primevue/usetoast';
import { MenuItem as MenuItemInterface } from '@/interface';
import { InputStrings } from './validation';
import MeetingBooking from './components/MeetingBooking.vue';

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Footer: defineAsyncComponent(() => import('@/components/Footer.vue')),
    Body,
    Button,
    Toast,
    Order: defineAsyncComponent(() => import('@/components/Order.vue')),
    PresentationOrder,
    MeetingBooking,
    // TariffOrder,
    // TimeTable,
  },
  setup() {
    // toast
    const toast = useToast();

    // new year
    const isNewYear = ref(true);
    provide('isNewYear', isNewYear);
    onMounted(() => {
      if (isNewYear.value) {
        setTimeout(() => {
          toast.add({
            severity: 'info',
            group: 'bc',
          });
        }, 3000);
      }
    });

    // timetable
    const hasTimetable = ref(false);
    // const hasTimetable = ref(
    //   window.location.pathname === '/schedule/'
    //   || process.env.NODE_ENV === 'development'
    // );

    const options = {
      autoConfig: true,
      debug: true,
    };

    // pixel
    // ReactPixel.init('424348205666828', undefined, options);
    ReactPixel.init('2861945907404430', undefined, options);
    // eslint-disable-next-line no-undef
    if (globalThis.fbq) globalThis.fbq.disablePushState = true;
    ReactPixel.pageView();

    // adaptive
    const { width } = useWindowSize();
    const isMobileView = ref(isMobile());
    watchEffect(() => {
      if (width.value) {
        if (width.value < 757) {
          isMobileView.value = true;
        } else {
          isMobileView.value = isMobile();
        }
      }
    });
    provide('isMobileView', isMobileView);

    // scrolling
    const isScrollingToBottom = ref(true);
    const lastScrollPosition = ref(0);
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      if (Math.abs(currentScrollPosition - lastScrollPosition.value) < 60) {
        return;
      }
      isScrollingToBottom.value = currentScrollPosition < lastScrollPosition.value;
      lastScrollPosition.value = currentScrollPosition;
    };
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    provide('isScrollingToBottom', isScrollingToBottom);

    // timetable
    const isTimeTableRequested = ref(true);
    provide('isTimeTableRequested', isTimeTableRequested);

    // order
    const isOrderRequested = ref(false);
    const isPhoneCheck = ref(false);
    const proceedToOrder = () => {
      isOrderRequested.value = true;
      isPhoneCheck.value = false;
    };
    provide('isOrderRequested', isOrderRequested);
    provide('isPhoneCheck', isPhoneCheck);
    provide('proceedToOrder', proceedToOrder);

    const selectedPromo = ref(null);
    provide('selectedPromo', selectedPromo);

    const tariffOrderRequested = ref(null as string | null);
    const isTariffOrderRequested = computed({
      get: () => tariffOrderRequested.value !== null,
      set: () => { tariffOrderRequested.value = null; },
    });
    const showTariff = (tariff: string) => {
      tariffOrderRequested.value = tariff;
    };
    provide('tariffOrderRequested', tariffOrderRequested);
    provide('isTariffOrderRequested', isTariffOrderRequested);
    provide('showTariff', showTariff);

    const isPresentationSent = ref(false);
    const isPresentationOrderRequested = ref(false);
    const requestPresentation = () => {
      isPresentationOrderRequested.value = true;
    };
    provide('isPresentationSent', isPresentationSent);
    provide('isPresentationOrderRequested', isPresentationOrderRequested);
    provide('requestPresentation', requestPresentation);
    const presentationLink = inject('presentationLink') as string;
    const downloadPresentation = () => {
      downloadFromLink(presentationLink, 'well-franchise-finance.xlsx');
      isPresentationSent.value = true;
      toast.add({
        severity: 'success',
        summary: 'Скачивание завершено',
        detail: 'Фин.показатели загружены',
        group: 'bl',
        life: 3000,
      });
    };
    provide('downloadPresentation', downloadPresentation);

    const moveToUrl = (hash = '') => {
      let destination = hash;
      if (!destination || typeof destination === 'object') {
        const e = window.event;
        if (e) { e.preventDefault(); }
        const link: EventTarget | null | undefined = e?.target;
        destination = (link as HTMLAnchorElement).hash;
      }

      console.log(destination);
      const section = document.getElementById(destination.slice(1));
      console.log(section);
      if (section) section.scrollIntoView({ behavior: 'smooth' });
    };
    provide('moveToUrl', moveToUrl);
    const menuItems: MenuItemInterface[] = [
      {
        label: 'Скачать фин.показатели',
        icon: 'pi pi-download',
        command: requestPresentation,
      },
      {
        separator: true,
      },
      {
        label: 'О нас',
        icon: 'pi pi-angle-right',
        id: '#about',
        command: () => moveToUrl('#about'),
      },
      {
        label: 'Что вы получите',
        icon: 'pi pi-angle-right',
        id: '#services',
        command: () => moveToUrl('#services'),
      },
      {
        label: 'Опрос',
        icon: 'pi pi-angle-right',
        id: '#steps',
        command: () => moveToUrl('#steps'),
      },
      {
        label: 'Отзывы',
        icon: 'pi pi-angle-right',
        id: '#reviews',
        command: () => moveToUrl('#reviews'),
      },
      {
        label: 'Слово руководителя',
        icon: 'pi pi-angle-right',
        id: '#director',
        command: () => moveToUrl('#director'),
      },
      {
        label: 'Почему туризм',
        icon: 'pi pi-angle-right',
        id: '#preferences',
        command: () => moveToUrl('#preferences'),
      },
      {
        label: 'Затраты',
        icon: 'pi pi-angle-right',
        id: '#cost',
        command: () => moveToUrl('#cost'),
      },
      {
        label: 'Этапы',
        icon: 'pi pi-angle-right',
        id: '#stages',
        command: () => moveToUrl('#stages'),
      },
      {
        label: 'Вы получите',
        icon: 'pi pi-angle-right',
        id: '#bonuses',
        command: () => moveToUrl('#bonuses'),
      },
      {
        label: 'Тарифы',
        icon: 'pi pi-angle-right',
        id: '#tariffs',
        command: () => moveToUrl('#tariffs'),
      },
      {
        label: 'Контакты',
        icon: 'pi pi-angle-right',
        id: '#contacts',
        command: () => moveToUrl('#contacts'),
      },
      {
        separator: true,
      },
      {
        label: 'Оставить заявку',
        icon: 'pi pi-envelope',
        command: proceedToOrder,
      },
    ];
    provide('menuItems', menuItems);
    const closeOrder = () => {
      isOrderRequested.value = false;
      isPresentationOrderRequested.value = false;
      tariffOrderRequested.value = null;
    };
    provide('closeOrder', closeOrder);

    const TEST_MEETING_BOOKING = new URLSearchParams(window.location.search).get('TEST_MEETING_BOOKING');
    const isMeetingBookingRequested = ref(!!TEST_MEETING_BOOKING);
    const bookMeeting = () => {
      isOrderRequested.value = false;
      isPresentationOrderRequested.value = false;
      isMeetingBookingRequested.value = true;
    };
    provide('bookMeeting', bookMeeting);
    provide('isMeetingBookingRequested', isMeetingBookingRequested);

    // const errors = ref([] as string[]);
    const showErrors = (errorsToShow: string[]) => {
      // errors.value = errorsToShow;
      errorsToShow.forEach((err) => {
        toast.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: err,
          group: 'bl',
          life: 3000,
        });
      });
    };
    provide('showErrors', showErrors);
    // const clearErrors = () => {
    //   errors.value = [];
    // };
    // provide('clearErrors', clearErrors);

    // save order
    const gateway = inject('gateway') as string;
    const saveStepsOrder = (fields: { [key: number]: InputStrings }) => axios
      .post(
        gateway,
        {
          type: 'steps_order',
          ...Object.keys(fields).reduce((response, step) => {
            Object.keys(fields[parseInt(step, 10)]).forEach((field) => {
              const value = `${fields[parseInt(step, 10)][field]}`;
              // eslint-disable-next-line no-param-reassign
              response[field] = value;
            });
            return response;
          }, {} as { [key: string]: string }),
        },
      )
      .then((r: AxiosResponse) => {
        if (r.data?.success) {
          ReactPixel.trackCustom('SaveStepsOrder', fields);
        }
        return {
          isSaved: r.data?.success,
          errors: r.data?.errors,
        };
      })
      .catch((e: Error) => console.log(e));
    provide('saveStepsOrder', saveStepsOrder);

    const orderId = ref(null);
    const saveOrder = (fields: { [key: number]: InputStrings }) => axios
      .post(
        gateway,
        {
          type: 'order',
          ...fields,
        },
      )
      .then((r: AxiosResponse) => {
        if (r.data?.success) {
          ReactPixel.trackCustom('SaveOrder', fields);
        }
        if (r.data?.order_id) {
          orderId.value = r.data?.order_id;
        }
        return {
          isSaved: r.data?.success,
          errors: r.data?.errors,
        };
      })
      .catch((e: Error) => console.log(e));
    provide('saveOrder', saveOrder);
    provide('orderId', orderId);

    const checkPhone = (fields: { [key: number]: InputStrings }) => axios
      .post(
        gateway,
        {
          type: 'check_phone',
          ...fields,
        },
      ).then((r: AxiosResponse) => {
        if (r.data?.success) {
          // new track
          // ReactPixel.trackCustom('SaveOrder', fields);
        }
        return {
          isSaved: r.data?.success,
          errors: r.data?.errors,
        };
      })
      .catch((e: Error) => console.log(e));
    provide('checkPhone', checkPhone);

    return {
      hasTimetable,
      proceedToOrder,
      toast,
      isNewYear,
      isMobile,
      // errors,
    };
  },
});
