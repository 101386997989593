
/* eslint-disable max-len */
// import { isMobile } from '@/api';
import {
  computed,
  defineComponent, inject, reactive, ref, Ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Listbox from 'primevue/listbox';
import Button from 'primevue/button';
import Chip from 'primevue/chip';

export default defineComponent({
  components: {
    Dialog,
    Calendar,
    // Listbox,
    Button,
    Chip,
    // Card,
    // RadioButton,
    // Chip,
  },
  setup() {
    const isBooked = ref(false);
    const date = ref() as Ref<Date>;
    const isMeetingBookingRequested = inject('isMeetingBookingRequested') as Ref<boolean>;

    function isUnavaliable(d) {
      const current = new Date(d.year, d.month, d.day);
      // console.log(d);
      if ([6, 0].includes(current.getDay())) {
        /* eslint-disable no-param-reassign */
        d.selectable = false;
        return true;
      }
      return false;
    }

    const disabledDays = [6, 0];
    const disabledDates = [
      new Date(2024, 11, 30),
      new Date(2024, 11, 31),
      ...(Array(8).fill(null).map((_, i) => new Date(2025, 0, i + 1))),
    ];

    const modalBottom = ref();
    const time = ref();
    function inputDate(e) {
      time.value = undefined;
      setTimeout(() => modalBottom.value.scrollIntoView({ behavior: 'smooth' }), 100);
    }

    const beforeBreak = [10, 11, 12, 13];
    const afterBreak = [14, 15, 16, 17];

    const timeAvaliable = computed(() => {
      if (!date.value) { return []; }
      if ([2, 3, 5].includes(date.value.getDay())) {
        return beforeBreak.map((n) => `${n}:00`);
      }
      return afterBreak.map((n) => `${n}:00`);
    });

    const months = inject('months') as string[];

    const dateString = computed(() => {
      if (!date.value) { return false; }
      const monthNum = date.value.getMonth();
      const month = months[monthNum];
      return `${date.value.getDate()} ${month} ${date.value.getUTCFullYear()}`;
    });

    const result = computed(() => {
      if (!time.value) { return false; }
      return `${dateString.value} в ${time.value}`;
    });
    const fields = inject('fields') as { [key: string]: string | boolean };
    const saveCommonOrder = inject('saveOrder') as CallableFunction;
    const showErrors = inject('showErrors') as CallableFunction;
    const fieldErrors = reactive({
      phone: null as null | string,
    });
    const orderId = inject('orderId') as Ref<number | null>;
    function acceptMeetingBooking() {
      // console.log();
      let utcDate = new Date(date.value.setHours(time.value.split(':')[0]));
      utcDate = new Date(utcDate.setMinutes(0));
      utcDate = new Date(utcDate.setSeconds(0));

      const utc = Math.floor(utcDate.getTime() / 1000);
      const { pin: _, ...send } = fields;
      saveCommonOrder({
        ...send,
        meeting_at: utc,
        time: result.value,
        order_id: orderId.value,
      })
        .then((
          { isSaved, errors }: { isSaved: boolean, errors: string[] },
        ) => {
          if (isSaved) {
            isBooked.value = true;
          } else {
            // fields.pin = '';
            showErrors(errors);
          }
        });
    }

    return {
      date,
      isUnavaliable,
      disabledDays,
      disabledDates,
      timeAvaliable,
      time,
      result,
      dateString,
      isMeetingBookingRequested,
      acceptMeetingBooking,
      fields,
      isBooked,
      inputDate,
      modalBottom,
      fieldErrors,
    };
  },
});
