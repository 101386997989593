
import {
  defineComponent, defineAsyncComponent, inject, reactive, ref, watchEffect,
} from 'vue';
import gsap from 'gsap';
// import StepsOrder from '@/components/StepsOrder.vue';
// import OfficeMap from '@/components/OfficeMap.vue';
import SvgBackground from '@/components/SvgBackground.vue';
// import NewYear from '@/components/NewYear.vue';
// import Reviews from '@/components/Reviews.vue';
import Stages from '@/components/Stages.vue';
// import Tariffs from '@/components/Tariffs.vue';
import SingleTariff from '@/components/SingleTariff.vue';
import SpecialOffer from '@/components/SpecialOffer.vue';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Chip from 'primevue/chip';
import Card from 'primevue/card';
// import { vueVimeoPlayer } from 'vue-vimeo-player';
import { Waypoint } from 'vue-waypoint';

// import { prettifyPhone } from '@/api';
// import { useToast } from 'primevue/usetoast';

export default defineComponent({
  name: 'Body',
  components: {
    Button,
    Waypoint,
    // StepsOrder,
    SvgBackground,
    Divider,
    Chip,
    Card,
    SpecialOffer,
    Reviews: defineAsyncComponent(() => import('@/components/Reviews.vue')),
    // OfficeMap,
    Stages,
    SingleTariff,
    // Tariffs,
    // vueVimeoPlayer,
    // NewYear,
  },
  setup() {
    const isNewYear = inject('isNewYear');

    const isMobile = inject('isMobileView');
    const proceedToOrder = inject('proceedToOrder') as (Event) => void;
    const services = inject('services');
    const posts = inject('posts');
    const preferences = inject('preferences');
    const bonuses = inject('bonuses');
    const endNumbers = [20, 150, 253, 110_000, 1_000_000];
    const numbers = reactive([...endNumbers]);
    const animateNumber = (index: number, firstTime = false) => {
      const maxNumber = endNumbers[index];
      const increment = Math.ceil(maxNumber / 50);
      if (firstTime && maxNumber === numbers[index]) {
        numbers[index] = 0;
      }
      if (numbers[index] < maxNumber) {
        if (numbers[index] + increment > maxNumber) {
          numbers[index] = maxNumber;
        } else {
          numbers[index] += increment;
        }
        setTimeout(() => {
          animateNumber(index, false);
        }, 40);
        // console.log(numbers);
      }
    };

    const forbesLink = ref(null);
    const bebossLink = ref(null);
    const heroCaption = ref(null);
    const heroSubmit = ref(null);

    const animateLinks = () => {
      gsap.fromTo(heroCaption.value, {
        y: 30,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        duration: 1,
      });
      gsap.fromTo(heroSubmit.value, {
        y: 30,
        opacity: 0.4,
      }, {
        opacity: 1,
        y: 0,
        delay: 1,
        duration: 1,
        ease: 'sine.inOut',
      });
      gsap.fromTo(forbesLink.value, {
        y: 30,
        opacity: 0.4,
      }, {
        y: 0,
        opacity: 1,
        duration: 3,
        yoyo: true,
      });
      // gsap.fromTo(bebossLink.value, {
      //   y: 30,
      //   opacity: 0.4,
      // }, {
      //   y: 0,
      //   delay: 1.1,
      //   opacity: 1,
      //   duration: 2,
      //   yoyo: true,
      // });
    };
    watchEffect(() => {
      if (forbesLink.value && heroSubmit.value) {
        animateLinks();
      }
    });

    const waypointChange = (index: number, e: { [key: string]: string }) => {
      // console.log(e);
      if (e.going === 'IN' && e.direction === 'UP') {
        animateNumber(index, true);
      }
    };

    const improveSpan = (html: string) => `&nbsp;${html.replace(/\s/g, '&nbsp; &nbsp;')}&nbsp;`;

    const requestPresentation = inject('requestPresentation');

    const video = ref(null as unknown as HTMLVideoElement);
    const isVideoPLaying = ref(false);
    const handlePlay = () => {
      // video.volume = 0.1;
      if (video.value.paused) {
        video.value.play();
        isVideoPLaying.value = true;
      }
    };

    const urlHost = (url) => new URL(url).host;
    return {
      isNewYear,
      urlHost,

      isMobile,
      proceedToOrder,
      waypointChange,

      forbesLink,
      bebossLink,
      heroCaption,
      heroSubmit,

      services,
      preferences,
      posts,
      bonuses,
      numbers,
      improveSpan,
      requestPresentation,
      video,
      handlePlay,
      isVideoPLaying,
    };
  },
});
